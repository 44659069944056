import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css, createGlobalStyle } from 'styled-components';
import '@fontsource/press-start-2p'; // Import the font

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 10px; /* Add padding to prevent text cutoff */
    background: black;
    color: white; /* Change text color to white */
    font-family: 'Press Start 2P', cursive;
    -webkit-font-smoothing: antialiased; /* Smooth text rendering */
    -moz-osx-font-smoothing: grayscale; /* Smooth text rendering */
    font-size: 14px; /* Set a standard font size */
  }
`;

const typing = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the top-left */
  justify-content: flex-start; /* Align items to the top-left */
  height: 100vh;
  background: black;
  color: white; /* Change text color to white */
  font-family: 'Press Start 2P', cursive;
  padding: 10px; /* Add padding to prevent text cutoff */
  font-size: 14px; /* Set a standard font size */
  line-height: 1.5em; /* Add vertical spacing */
`;

const Line = styled.div`
  white-space: pre-wrap; /* Enable text wrapping */
  margin: 0;
  letter-spacing: .15em;
  display: inline-block;
  border-right: ${({ showCursor }) => (showCursor ? '.15em solid white' : 'none')};
  animation: ${({ steps }) =>
    css`${typing} ${steps * 0.1}s steps(${steps}, end) forwards`};
`;

const App = () => {
  const [currentLine, setCurrentLine] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [outputLines, setOutputLines] = useState([]);
  const inputRef = useRef(null);

  const bootSequence = [
    'Initializing system...',
    'Loading modules...',
    'Establishing secure connection...',
    'Bypassing censorship protocol...',
    'Accessing Solana network...',
    'Decrypting $BANNED token...',
    'System ready. Welcome.',
    '$BANNED Address: 416N3SBcLsTvPqiP84YDGXzCK3Av7BHGcRFEYC42pump',
    'Type "help" for a list of commands.',
  ];

  useEffect(() => {
    if (currentLine < bootSequence.length) {
      const timer = setTimeout(() => {
        setCurrentLine(currentLine + 1);
      }, bootSequence[currentLine].length * 100 + 500);
      return () => clearTimeout(timer);
    } else {
      inputRef.current.focus();
    }
  }, [currentLine, bootSequence.length]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSubmit = (e) => {
    if (e.key === 'Enter') {
      const userCommand = inputValue.trim();
      let newOutputLines = [...outputLines, `> ${userCommand}`];

      if (userCommand.toLowerCase() === 'help') {
        newOutputLines = [
          ...newOutputLines,
          'Available commands:',
          '- help: Show this help menu',
          '- check [username]: Check if a username is banned',
          '- dextools: Open Dextools for $BANNED token',
        ];
      } else if (userCommand.toLowerCase().startsWith('check ')) {
        const username = userCommand.slice(6);
        newOutputLines = [
          ...newOutputLines,
          `Analyzing... ${username} is shadow banned`,
        ];
      } else if (userCommand.toLowerCase() === 'dextools') {
        window.open('https://www.dextools.io/app/en/solana/pair-explorer/BceXMw54VQsmeqmiTX8BwSrk7KPfEEpqsJZcv96421zr', '_blank');
      } else {
        newOutputLines = [
          ...newOutputLines,
          `Unknown command: ${userCommand}`,
        ];
      }

      setOutputLines(newOutputLines);
      setInputValue('');
      setTimeout(() => {
        inputRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {bootSequence.slice(0, currentLine).map((line, index) => (
          <Line key={index} steps={line.length} showCursor={false}>
            {line}
          </Line>
        ))}
        {outputLines.map((line, index) => (
          <Line key={index} steps={line.length} showCursor={false}>
            {line}
          </Line>
        ))}
        <Line showCursor={true} as="div">
          <span>{inputValue}</span>
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputSubmit}
            style={{
              background: 'transparent',
              border: 'none',
              color: 'white',
              outline: 'none',
              fontFamily: 'Press Start 2P',
              fontSize: '14px',
              caretColor: 'transparent',
            }}
          />
        </Line>
      </Wrapper>
    </>
  );
};

export default App;
